import { useMemo, useState } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { Decal, useGLTF, useTexture } from '@react-three/drei';

const Teeth = (props) => {
  const [active1, setActive1] = useState(true);

  // const tooth = useGLTF('/images/tooth_v2.glb');
  const tooth = useGLTF('/images/Tooth_soft.glb');
  const tooth2 = useGLTF('/images/Tooth_soft2.glb');
  const tooth_foam = useGLTF('/images/Tooth_foam.glb');

  // const texture = useTexture("/images/thermal_v1.png");

  // texture.repeat.set(2, 1);
  const geometry = useMemo(() => {
    let g;
    tooth.scene.traverse((node) => {
      if (node.isMesh) {
        g = node.geometry;
      }
    });
    return g;
  }, [tooth]);
  console.log('props.brushProg', props.brushProg);
  return (
    <>
      <animated.primitive object={tooth.scene} position={[0, -6.5, props.posZ]} rotation={[0, 1.57, 0]} scale={[2, 2, 2]}
        onClick={props.onClick} onPointerEnter={props.projState == 12 ? props.onPointerEnter : null} onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove}>
      </animated.primitive>
      <animated.primitive object={tooth2.scene} position={[0, -6.5, -props.posZ]} rotation={[0, 1.57, 0]} scale={[2, 2, 2]}
        onClick={props.onClick} onPointerEnter={props.projState == 18 ? props.onPointerEnter : null} onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove}>
      </animated.primitive>

      <animated.primitive object={tooth_foam.scene} position={props.projState >= 18 ? [0, -6.5, -props.posZ] : [0, -6.5, props.posZ]} rotation={[0, 1.57, 0]}
        scale={[1.6, 1.6 + (props.brushProg * (0.2 / 100)), 1.5 + (props.brushProg * (0.8 / 100))]}
        onPointerEnter={props.projState == 12 || props.projState == 18 ? props.onPointerEnter : null} onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove}>
      </animated.primitive>

      {/* <mesh geometry={geometry} scale={2.5} rotation={[-1.47, 0, -2.64]} position={[0, -7, props.posZ]} castShadow>
        <meshStandardMaterial color={'#ddd'} roughness={0} metalness={0} />
        <Decal position={[-0.4, 0, 0.4]}
          rotation={[0, 1.57, 0]}
          scale={0.4} map={texture} />
      </mesh>*/}
      {/* <animated.mesh geometry={geometry} position={[0, 0, 0]} rotation={[0, 1.57, 0]} scale={2} castShadow
        onClick={props.onClick} onPointerEnter={props.onPointerEnter} onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove}>
        <meshStandardMaterial color={'#fff'} roughness={0} metalness={0} />
        <Decal position={[-0.4, 0, 0.4]}
          rotation={[0, 1.57, 0]}
          scale={0.4} map={texture} />
      </animated.mesh> */}

    </>
  );
}

export default Teeth;