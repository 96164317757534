import { useNavigate } from "react-router-dom";
import './splashscreencoat.css';

const SplashScreenCoat = () => {
  const navigate = useNavigate();

  return (
    <div className="splash_coat_screen">
      <div className="splash_coat_title">Get Ready to enter</div>
      <div className="splash_coat_logo_cont">
        <img src="/images/Pepsodent_logo.svg" className="splash_coat_logo" />
        <div className="splash_coat_subtitle">Lab</div>
      </div>
      <button className="btn_custom" onClick={() => navigate('/demo')}>Get Started</button>
      <img src="/images/Pepsodent_vector.svg" className="splash_coat_vector" />
      <img src="/images/Lab_coat@5x.png" className="splash_coat" />
      <img src="/images/Pepsodent_logo.svg" className="splash_coat_logo_small" />
    </div>
  );
};

export default SplashScreenCoat;