import { Html } from '@react-three/drei';
import { useNavigate } from 'react-router-dom';

const Pointer = (props) => {
  const { innerWidth: width, innerHeight: height } = window;
  const ppj = props.projState;
  const navigate = useNavigate();

  return (
    <>
      {ppj <= 0 || ppj == 7 || ppj == 8 || ppj == 9 || ppj == 12 || ppj == 15 || ppj == 18 || ppj == 20 || (ppj == 21 && props.counter == 100) ?
        <Html position={ppj == 0 ? [0, 4, 0]
          : ppj == 7 || ppj == 8 || ppj == 20 || ppj == 21 ? [0, 0.2, 0]
            : ppj == 9 ? [0, -5, 13.5]
              : ppj == 12 ? [0, -0.5, 2.5]
                : ppj == 15 ? [0, -5, -13.5]
                  : ppj == 18 ? [0, -0.5, -2.5]
                    : [0, 4, 0]}>
          <div >
            {/* <img src='/images/cursor_pointer.svg' className='cursor_pointer'
              style={ppj >= 0 ? { display: 'none' } : {}} /> */}
            <div className='pointer_msg'
              style={ppj >= -1 && ppj <= 7 || ppj == 20 ?
                (width >= 900 && width <= 1200 ? { top: '3vw', bottom: 'unset', left: '-10vw', width: '20vw' }
                  : { top: '3vw', bottom: 'unset', left: '-7vw', width: '14vw' })
                : ppj == 8 || ppj == 21 ?
                  (width >= 900 && width <= 1200 ? { top: '3vw', bottom: 'unset', left: '-12vw', width: '24vw' }
                    : { top: '3vw', bottom: 'unset', left: '-8vw', width: '16vw' })
                  : ppj == 12 ?
                    (width >= 900 && width <= 1200 ? { top: '3vw', bottom: 'unset', left: '-10vw', width: '18vw' }
                      : { top: '3vw', bottom: 'unset', left: '-8vw', width: '12vw' })
                    : ppj == 18 ?
                      (width >= 900 && width <= 1200 ? { top: '3vw', bottom: 'unset', left: '-8vw', width: '18vw' }
                        : { top: '3vw', bottom: 'unset', left: '-7vw', width: '12vw' })
                      : {}}>
              {ppj <= 0 && <>Click on the bottle to drop the sugar acid on teeth</>}
              {ppj == 7 && <>Click on the teeth to observe the demineralisation</>}
              {ppj == 8 && <>Tiny invisible holes due to loss of calcium minerals, begin to form.
                <div onClick={props.onClick} style={{ cursor: 'pointer', color: 'var(--primary-red)' }}><b><i><u>Next</u></i></b></div>
              </>}
              {(ppj == 9) && <>Click on the regular Pepsodent to apply on the toothbrush.</>}
              {(ppj == 15) && <>Click on the double calcium boosted Pepsodent to apply on the toothbrush.</>}
              {(ppj == 12 || ppj == 18) && <>Brush the tooth to apply the paste. <div className='brush_progress'>
                <div style={{ width: props.brushProg + '%' }}></div>
              </div></>}
              {ppj == 20 && <>Click on the teeth to observe the remineralisation</>}
              {(ppj == 21 && props.counter == 100) && <>Click next to see the result
                <div onClick={() => navigate('/conclusion')} style={{ cursor: 'pointer', color: 'var(--primary-red)' }}><b><i><u>Next</u></i></b></div>
              </>}
            </div>
          </div>
        </Html > : null
      }
    </>
  );
}

export default Pointer;