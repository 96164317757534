const Base = (props) => {
  return (
    <mesh position={[0, -9.5, 0]} {...props}
      receiveShadow >
      <boxGeometry args={[8, 2, 12]} />
      <meshStandardMaterial metalness={0} roughness={1}
        color="rgb(0,46,187)"
        opacity={0.8} transparent />
    </mesh>
  );
}

export default Base;