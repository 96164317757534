import { useRef } from 'react';
import { OrbitControls } from '@react-three/drei';

const Controls = (props) => {
  const controlRef = useRef();
  return (
    props.projState >= -1 ?
      <OrbitControls
        ref={controlRef}
        enablePan={false}
        enableZoom={true}
        enableRotate={true}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        minDistance={props.zoomConst}
        maxDistance={props.zoomConst}
        zoomSpeed={0.5}
        minAzimuthAngle={0}
        maxAzimuthAngle={0}
      /> :
      <OrbitControls
        ref={controlRef}
        enablePan={true}
        enableZoom={true}
        enableRotate={true}
        minPolarAngle={Math.PI / 3}
        maxPolarAngle={Math.PI / 2}
        minDistance={0}
        maxDistance={50}
        zoomSpeed={1}
        minAzimuthAngle={0}
        maxAzimuthAngle={Math.PI * 1.999}
      />
  );
}

export default Controls;