import { Html } from "@react-three/drei";
import { animated } from '@react-spring/three';

const ResText = (props) => {
  // const [counter, setCounter] = useState(0);

  // const AnimatedText = animated(Text)
  // const textureScale1 = useSpring({ counter: {}, config: { duration: 100 }, onRest: setCounter(counter + 1) })
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <>
      <animated.group>
        <Html position={[0, -3.1, 3.9]}>
          <div className="text_final_counter">{(props.counter * 74 / 100).toFixed(0)}%
            <div >Remineralization</div></div>
        </Html>
        <Html position={[0, -3.1, -1.4]}>
          <div className="text_final_counter">{(props.counter * 104 / 100).toFixed(0)}%
            <div >Remineralization</div></div>
        </Html>
        <Html position={width >= 900 && width <= 1200 ? [0, 1.5, -6.5] : [0, 1.5, -11.5]}>
          <div className="text_final_days">
            {(props.counter * 40 / 100).toFixed(0)}
            <div>Days</div></div>
        </Html>
        {/* <AnimatedText rotation={[0, 1.57, 0]}>{textureScale1.num}</AnimatedText> */}
      </animated.group>
    </>
  )
}

export default ResText;