import { useNavigate } from "react-router-dom";
import './splashscreen.css';

const SplashScreen = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const navigate = useNavigate();

  // setTimeout(() => {
  //   navigate("/ready-to-enter")
  // }, 4000);

  return (
    width < 900 ?
      <div className="splash_screen">
        <img src="/images/Pepsodent_logo.svg" className="splash_logo" />
        <div className="splash_title">10X</div>
        <div className="splash_subtitle">STRONGER<br />TEETH</div>
        <img src="/images/Pepsodent_vector.svg" className="splash_vector" />
        <img src="/images/Splash_paste.png" className="splash_paste" />
      </div>
      :
      // <img src="/images/splash_screen_v2.jpg" className="splash_desktop" />
      <div className="splash_screen">
        <img src="/images/Pepsodent_logo.svg" className="splash_logo" />
        <div className="splash_title_cont">
          <div className="splash_title">10X</div>
          <div className="splash_subtitle">STRONGER<br />TEETH</div>
        </div>
        <div className="splash_img_cont">
          {/* <img src="/images/splash_eggs.png" className="splash_eggs" /> */}
          <img src="/images/splash_screen_asset.png" className="splash_paste" />
        </div>
      </div>
  );
};
// Splash-Desktop
export default SplashScreen;