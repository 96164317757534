import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useThree, useFrame, useLoader } from '@react-three/fiber';
import Brush from './brush';
import Toothpaste from './toothpaste';
import Paste from './paste';

const Sound = (props) => {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, '/images/brush-sound-1.mp3');
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(2);
    sound.current.setLoop(true);
    props.brushActive && props.brushMove && (props.projState === 12 || props.projState === 18) ? sound.current.play() : sound.current.pause();
    camera.add(listener);
    return () => camera.remove(listener);
  }, [props.brushActive, props.brushMove, props.projState]);
  return <positionalAudio ref={sound} args={[listener]} />;
}

const BrushGroup = (props) => {
  const brushRef = useRef();
  const brushRef2 = useRef();
  const { viewport } = useThree();

  // let brushTime = 0;
  useFrame(({ mouse }) => {
    // console.log('active', props.brushActive);
    if (props.brushActive && (props.projState == 12 || props.projState == 18)) {
      if (props.projState == 12) {
        const x = ((mouse.x * viewport.width) / 7) - 1;
        const y = (mouse.y * viewport.height / 5) - 5;
        brushRef2.current.position.set(3, y, -x);
        brushRef2.current.rotation.set(Math.PI, 0, Math.PI / 2);
        props.setBrushProg();
        if (props.brushProg >= 100) {
          props.setCircleAnim();
        }
      }

      else if (props.projState == 18) {
        const x = ((mouse.x * viewport.width) / 7) + 1;
        const y = (mouse.y * viewport.height / 5) - 5;
        brushRef.current.position.set(3, y, -x);
        brushRef.current.rotation.set(0, 0, Math.PI / 2);
        props.setBrushProg();
        if (props.brushProg >= 100) {
          props.setCircleAnim();
        }
      }
    } else {
      props.resetBrushProg();
      brushRef.current.position.set(2, -10.1, -17);
      brushRef.current.rotation.set(0, Math.PI, 0);

      brushRef2.current.position.set(2, -10.1, 17);
      brushRef2.current.rotation.set(0, 0, 0);
    }
  })

  return (
    <group position={[0, 0, 0]}>
      <group ref={brushRef}>
        <Sound brushActive={props.brushActive} brushMove={props.brushMove} projState={props.projState} />
        <Brush onClick={props.onClick} asset={'/images/Brush_new.glb'} />
        <Paste projState={props.projState} asset={'/images/Paste.glb'} />
      </group>
      <group ref={brushRef2}>
        <Sound brushActive={props.brushActive} brushMove={props.brushMove} projState={props.projState} />
        <Brush onClick={props.onClick} asset={'/images/Brush_new2.glb'} />
        <Paste projState={props.projState} asset={'/images/Paste2.glb'} />
      </group>
      <Toothpaste projState={props.projState} onClick={props.onClick} onTimeOut={props.onTimeOut} />
    </group>
  )
}

export default BrushGroup;