import { Html, useProgress } from "@react-three/drei";

const Loader = (props) => {
  const { active, progress, errors, item, loaded, total } = useProgress();
  console.log('loaded', loaded, active, item, total);
  const { innerWidth: width, innerHeight: height } = window;
  if (!active)
    props.setPageLoaded();

  return (
    <>
      {width >= 600 ?
        <Html center>
          <div className="loader_text">
            Loading Smiles...<br />
            {progress.toFixed(0)} %
            <div className="loader_img_cont" >
              <div className="loader_img" style={{ height: 8 / 100 * progress + 'vw' }}>
              </div>
            </div>
          </div>
        </Html>
        : null
      }
    </>
  )
}

export default Loader;