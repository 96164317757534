import { Decal, useGLTF, useTexture } from "@react-three/drei";
import { useMemo, useRef } from "react";
import { useSpring, animated } from '@react-spring/three';

const Toothpaste = (props) => {
  const toothPaste = useGLTF('/images/Pepsodent_Tube_DCB.glb');
  // const toothPaste2 = useGLTF('/images/Pepsodent_Tube2.glb');
  const cap = useGLTF('/images/cap_toothpaste.glb');
  // const cap2 = useGLTF('/images/cap_toothpaste2.glb');
  // cap_toothpaste.glb
  toothPaste.scene.traverse((node) => {
    if (node.isMesh) { node.castShadow = true; }
  });
  cap.scene.traverse((node) => {
    if (node.isMesh) { node.castShadow = true; }
  });

  // toothPaste2.scene.traverse((node) => {
  //   if (node.isMesh) { node.castShadow = true; }
  // });
  // cap2.scene.traverse((node) => {
  //   if (node.isMesh) { node.castShadow = true; }
  // });

  const geometryToothPaste = useMemo(() => {
    let g;
    toothPaste.scene.traverse((node) => {
      if (node.isMesh) {
        g = node.geometry;
      }
    });
    return g;
  }, [toothPaste]);

  const geometryCap = useMemo(() => {
    let g;
    cap.scene.traverse((node) => {
      if (node.isMesh) {
        g = node.geometry;
      }
    });
    return g;
  }, [cap]);


  const tpPos = useSpring({
    position: props.projState == 16 ? [2, -8, -17] : props.projState == 17 ?
      [2, -7.9, -19] : [0, -8.9, -9], config: { duration: 800 },
    onRest: props.onTimeOut
  });
  const tpRot = useSpring({
    rotation: props.projState == 16 ? [0.6, 0, -1.5] : props.projState == 17 ?
      [0.8, 0, -1.5] : [0, 0, -1.5], config: { duration: 800 }
  });

  const capPos = useSpring({
    position: props.projState == 16 || props.projState == 17 ?
      [0, -9.9, -7.6] : [0, -8.9, -7.6], config: { duration: 800 },
    onRest: props.onTimeOut
  });
  const capRot = useSpring({
    rotation: props.projState == 16 || props.projState == 17 ?
      [-1.57, 0, 0] : [0, 0, 0], config: { duration: 800 }
  });

  // const texture = useTexture("/images/thermal_v1.png");
  // const texture = useTexture("/images/Pepsodent 1-01.svg");
  // texture.offset.set(-0.05);
  // texture.wrapS.set();
  // texture.repeat.set(0.1, 0.1);
  const tpPos2 = useSpring({
    position: props.projState == 10 ? [2, -8, 17] : props.projState == 11 ?
      [2, -7.9, 19] : [0, -8.9, 9], config: { duration: 800 },
    onRest: props.onTimeOut
  });
  const tpRot2 = useSpring({
    rotation: props.projState == 10 ? [-0.6, 3.14, -1.5] : props.projState == 11 ?
      [-0.8, 3.14, -1.5] : [0, 3.14, -1.5], config: { duration: 800 }
  });

  const capPos2 = useSpring({
    position: props.projState == 10 || props.projState == 11 ?
      [0, -9.9, 7.6] : [0, -8.9, 7.6], config: { duration: 800 },
    onRest: props.onTimeOut
  });
  const capRot2 = useSpring({
    rotation: props.projState == 10 || props.projState == 11 ?
      [-1.57, 0, 0] : [0, 3.14, 0], config: { duration: 800 }
  });

  return (<>
    <animated.primitive object={toothPaste.scene}
      onClick={props.onClick}
      scale={[0.06, 0.06, 0.06]}
      position={tpPos.position}
      rotation={tpRot.rotation}
    />
    <animated.primitive object={cap.scene}
      onClick={props.onClick}
      scale={[0.05, 0.05, 0.05]}
      position={capPos.position}
      rotation={capRot.rotation}
    />

    {/* <animated.primitive object={toothPaste2.scene}
      onClick={props.onClick}
      scale={[0.06, 0.06, 0.06]}
      position={tpPos2.position}
      rotation={tpRot2.rotation}
    /> */}
    {/* <animated.primitive object={cap2.scene}
      onClick={props.onClick}
      scale={[0.05, 0.05, 0.05]}
      position={capPos2.position}
      rotation={capRot2.rotation}
    /> */}

    <animated.mesh geometry={geometryToothPaste} scale={0.06}
      rotation={tpRot2.rotation} position={tpPos2.position} onClick={props.onClick} castShadow>
      <meshStandardMaterial color={'#888'}
        //  map={texture} 
        roughness={0.2} metalness={0} />
    </animated.mesh>

    <animated.mesh geometry={geometryCap} scale={0.05}
      rotation={capRot2.rotation} position={capPos2.position} onClick={props.onClick} castShadow>
      <meshStandardMaterial color={'#aaa'} roughness={0.2} metalness={0} />
      {/* <Decal position={[-0.4, 0, 0.4]}
        rotation={[0, 1.57, 0]}
        scale={0.4} map={texture} /> */}
    </animated.mesh>
  </>);
}

export default Toothpaste;