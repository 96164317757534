import { useGLTF } from "@react-three/drei";

const Brush = (props) => {
  const gltf = useGLTF(props.asset);
  gltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; node.receiveShadow = true; }
  });
  return (
    <primitive object={gltf.scene}
      scale={[80, 80, 80]}
      onClick={props.onClick}
    />
  )

}

const BrushNew = (props) => {
  const { nodes, materials } = useGLTF("/images/Brush_new.glb");
  console.log(nodes, materials, Object.values(materials)[0]);
  return (
    <group
      {...props}
      dispose={null}
      rotation={[0, 0, 0]}
      scale={[80, 80, 80]}
      position={[0, 0, 0]}
    >
      <mesh
        geometry={nodes.Brush.geometry}
      // material={Object.values(materials)[0]}
      >
        <meshStandardMaterial color={'#fff'} />
      </mesh>
    </group>
  );
}
export default Brush;