import { animated, useSpring } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { useMemo } from "react";

const Dropper = (props) => {
  const bottleGltf = useGLTF('/images/dropper_bottle.glb');
  const needleGltf = useGLTF('/images/dropper_needle.glb');
  const dropGltf = useGLTF('/images/drop_of_water.glb');

  bottleGltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });
  needleGltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });

  const ppj = props.projState;

  const geometryDrop = useMemo(() => {
    let g;
    dropGltf.scene.traverse((node) => {
      if (node.isMesh) {
        g = node.geometry;
      }
    });
    return g;
  }, [dropGltf]);

  const needlePos = useSpring({
    position: ppj >= 1 && ppj <= 3 ? [1, 0, 2.5]
      : ppj >= 4 && ppj <= 6 ? [1, 0, -2.5]
        : [7, -7, 0], config: { duration: 800 }, onRest: props.onTimeOut
  });
  const needleScale = useSpring({
    scale: ppj >= 1 && ppj <= 6 ? [1.2, 1.2, 1.2]
      : [1, 1, 1], config: { duration: 800 }
  });

  const dropPos = useSpring({
    position: ppj == 1 ? [1, -3, 2.5]
      : ppj == 2 || ppj == 3 ? [2, -6, 2.5]
        : ppj == 4 ? [1, -3, -2.5]
          : ppj == 5 || ppj == 6 ? [2, -6, -2.5]
            : [7, -7, 0], config: { duration: 800 }, onRest: props.onTimeOut
  });
  const dropScale = useSpring({
    scale: ppj == 2 || ppj == 5 ? [0.2, 0.2, 0.2]
      : [0, 0, 0], config: { duration: 800 }, onRest: props.onTimeOut
  });

  return (
    <group onClick={props.onClick}>
      {ppj <= 0 && <primitive object={bottleGltf.scene}
        scale={[1, 1, 1]}
        position={[7, -8.8, 0]}
        onClick={props.onClick}
      />}
      {ppj <= 6 && <animated.primitive object={needleGltf.scene}
        scale={needleScale.scale}
        position={needlePos.position}
        onClick={props.onClick}
      />}
      {/* <animated.primitive object={dropGltf.scene}
        scale={dropScale.scale}
        position={dropPos.position}
        onClick={props.onClick}
      /> */}
      <animated.mesh geometry={geometryDrop} scale={dropScale.scale}
        position={dropPos.position} onClick={props.onClick} castShadow >
        <meshStandardMaterial color={'#f9e290'} roughness={0} metalness={0} transparent opacity={0.8} />
      </animated.mesh>
    </group>
  )

}

export default Dropper;