import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { useSpring, animated, Spring } from '@react-spring/three';
import { Environment, Html, Image, useGLTF } from '@react-three/drei';
import Floor from '../../assets/floor';
import Table from '../../assets/table';
import Teeth from '../../assets/teeth';
import Base from '../../assets/base';
import BrushGroup from '../../assets/brushgroup';
import Controls from '../../assets/controls';
import Text from '../../assets/text';
import CircleAnim from '../../assets/circleanim';
import Loader from '../../assets/loader';
import Pointer from '../../assets/pointer';
import Portrait from '../../assets/portrait';
import Dropper from '../../assets/dropper';
import './demo.css';

const Demo = () => {
  const [projState, setProjState] = useState(-1);
  const [brushActive, setBrushActive] = useState(false);
  const [brushMove, setBrushMove] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  console.log('projState', projState, brushMove);
  const { innerWidth: width, innerHeight: height } = window;
  // const zoomConst = width >= 900 ? 30 : width >= 600 ? 32 : 50;
  const zoomConst = width >= 1200 ? 30 : width >= 900 ? 40 : 30;
  // const fov = width >= 900 ? 50 : width >= 600 ? 40 : 70;
  const fov = width >= 1200 ? 50 : 40;
  const posZ = 2.5;
  const groupPos = useSpring(
    width >= 900 && width <= 1200 ?
      {
        position: projState <= 0 ? [0, 3, 0]
          : projState >= 1 && projState <= 8 || projState >= 12 && projState <= 14 || projState >= 18 && projState <= 25 ? [0, 5, zoomConst - 20]
            : projState >= 9 && projState <= 11 ? [10, 5, zoomConst - 25]
              : projState >= 15 && projState <= 17 ? [-10, 5, zoomConst - 25]
                : [0, 3, 7], config: { duration: 800 }
      }
      : {
        position:
          projState >= 1 && projState <= 8 || projState >= 12 && projState <= 14 || projState >= 18 && projState <= 25 ? [0, 5, zoomConst - 16]
            : projState >= 9 && projState <= 11 ? [10, 5, zoomConst - 20]
              : projState >= 15 && projState <= 17 ? [-10, 5, zoomConst - 20]
                : [0, 3, 0], config: { duration: 800 }
      });
  const groupRotate = useSpring({ rotation: projState >= 1 && projState <= 3 ? [0, -Math.PI / 2, 0] : [0, -Math.PI / 2, 0], config: { duration: 800 } });
  const lightPos = useSpring({ position: projState >= 1 && projState <= 6 ? [5, 10, zoomConst] : [0, 20, -30], config: { duration: 800 } });
  const [brushProg, setBrushProg] = useState(0);

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (projState == 21 && counter < 100) {
      const interval = setInterval(() => {
        setCounter((counter) => counter + 1);
      }, 50);

      return () => {
        clearInterval(interval);
      };
    }
  }, [projState, counter]);

  return (
    <>
      <Portrait />
      <button className='btn_custom reset_btn' onClick={() => { setProjState(0); setCounter(0); }}>Restart</button>
      <Canvas shadows camera={{ position: [0, 0, zoomConst], fov: fov }}>
        <Suspense fallback={<Loader setPageLoaded={() => { setPageLoaded(true); setProjState(0) }} />}>
          <animated.group rotation={[0, -Math.PI / 2, 0]} position={groupPos.position}>
            <Floor />
            <Teeth posZ={posZ} onPointerEnter={() => setBrushActive(true)}
              onPointerLeave={() => { setBrushActive(false); setBrushMove(false); }}
              onPointerMove={() => { setBrushMove(true); }}
              projState={projState}
              brushProg={brushProg}
              onClick={() => projState == 7 || projState == 20 ? setProjState(projState + 1) : null} />
            <Base posZ={posZ} />
            <CircleAnim posZ={posZ} projState={projState}
              setCircleAnim={() => projState == 13 || projState == 14 || projState == 19 ? setProjState(projState + 1) : null} />

            {projState == 21 && <Text counter={counter} projState={projState} />}
            <Pointer projState={projState}
              brushProg={brushProg}
              counter={counter}
              onClick={() => projState == 8 ? setProjState(projState + 1) : null}
            />

            <Dropper onClick={() => projState == -1 ? setProjState(projState + 2) : projState == 0 ? setProjState(projState + 1) : null}
              onTimeOut={() => projState >= 1 && projState <= 6 ? setProjState(projState + 1) : null}
              projState={projState} />
            <Table />

            <BrushGroup projState={projState} brushActive={brushActive} brushMove={brushMove}
              brushProg={brushProg}
              setBrushProg={() => setBrushProg(brushProg + 1)}
              resetBrushProg={() => setBrushProg(0)}
              onClick={() => projState == 9 || projState == 15 ? setProjState(projState + 1) : null}
              onTimeOut={() => projState == 10 || projState == 11 || projState == 16 || projState == 17 ? setProjState(projState + 1) : null}
              setCircleAnim={() => projState == 12 || projState == 18 ? setProjState(projState + 1) : null} />

            {projState == 8 || projState == 21 ?
              <Html position={width >= 900 && width <= 1200 ? [0, 1, 5.2] : [0, 1, 6.2]}>
                <div className='qlf_title'>Quantitative Light induced Fluorescence</div>
              </Html> :
              null}
            <Image url='/images/Just_Texture.png'
              position={[1.8, -6, 2]}
              rotation={[0, 1.62, 0]}
              scale={projState == 8 || projState == 21 ? [1, 1] : [0, 0]} transparent></Image>
            <Image url='/images/Just_Texture.png'
              position={[1.8, -6, -3]}
              rotation={[0, 1.62, 0]}
              scale={projState == 8 || projState == 21 ? [1, 1] : [0, 0]} transparent></Image>
            {/* <Image url='/images/Just_Texture_Green.png'
              position={[1.82, -6, 2]}
              rotation={[0, 1.62, 0]}
              scale={projState == 21 ? counter / 100 * (74 / 100) : 0} transparent></Image> */}
            <Image url='/images/texture_green@4x.png'
              position={[1.82, -6, 2]}
              rotation={[0, 1.62, 0]}
              scale={projState == 21 ? counter / 100 * (74 / 100) : 0} transparent></Image>
            {/* <Html position={[0, -5.6, -2.9]}
              rotation={[0, 1.62, 0.2]}
              scale={[1, 1, 1]}
            >
              <div style={{width: '3vw', height: '3vw', borderRadius: '100vw', background: '#297831'}}> </div>
            </Html> */}
            <Image url='/images/texture_green@4x.png'
              position={[1.82, -6, -3]}
              rotation={[0, 1.62, 0]}

              scale={projState == 21 ? counter / 100 * (104 / 100) : 0} transparent></Image>
            <Image url='/images/Pepsodent_Remin.png'
              position={[5, -8.8, 0]}
              rotation={[0, 1.57, 0]}
              scale={projState == 8 || projState == 21 ? [6, 2] : [0, 0]} transparent></Image>

          </animated.group>
          <Controls projState={projState} zoomConst={zoomConst} />
          {projState == 8 || projState == 21 ? <color attach="background" args={['#000']} /> :
            <Environment files={"/images/Updated.2.hdr"} background blur={1} />}
          <animated.pointLight position={[5, 10, zoomConst]}
            intensity={projState == 8 || projState == 21 ? 0.2 : 0.8}
            color={projState == 8 || projState == 21 ? 0x88ff88 : 0xffffff} castShadow />
        </Suspense>
      </Canvas >
    </>
  );
}

export default Demo;
