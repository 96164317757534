import { useRef } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';

const CircleAnim = (props) => {
  const red_circle = useGLTF('/images/Red_Circle.glb');
  const blue_circle = useGLTF('/images/Blue_Circle.glb');

  const redCircleRef = useRef();
  const blueCircleRef = useRef();
  useFrame(() => {
    redCircleRef.current.rotation.z -= 0.06;
    // redCircleRef.current.rotation.x += 0.08;
    blueCircleRef.current.rotation.y += 0.06;
    // blueCircleRef.current.rotation.x -= 0.08;
  });

  const circle_anim = useSpring({
    scale: props.projState == 13 ? 0.39 : props.projState == 19 ? 0.75 : 0.4, config: { duration: props.projState == 13 || props.projState == 14 ? 100 : 1000 },
    onRest: props.setCircleAnim
  });

  return (
    <animated.group scale={circle_anim.scale} position={props.projState >= 19 ? [0, -6.2, -props.posZ] : [0, -6.2, props.posZ]}>
      <primitive ref={redCircleRef} object={red_circle.scene}
        scale={[30, 30, 30]} rotation={[-0.8, 0, 0]} />
      <primitive ref={blueCircleRef} object={blue_circle.scene}
        scale={[30, 30, 30]} rotation={[-0.8, 0, 0]} />
    </animated.group>
  );
}

export default CircleAnim;