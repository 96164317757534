import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from '@react-spring/three';

const Paste = (props) => {
  const gltf = useGLTF(props.asset);
  gltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });

  const pasteScale = useSpring({
    scale: props.projState >= 11 && props.projState <= 12 || props.projState >= 17 && props.projState <= 18 ?
      [500, 400, 550] : [0, 0, 0], config: { duration: 800 }
  });
  const pastePos = useSpring({
    postion: props.projState >= 11 && props.projState <= 12 || props.projState >= 17 && props.projState <= 18 ?
      [0, 0.7, -0.75] : [0, 0.7, -0.75], config: { duration: 800 }
  });

  return (<animated.primitive object={gltf.scene}
    position={pastePos.postion}
    scale={pasteScale.scale}
    rotation={[0, Math.PI, 0]}
  />);
}

export default Paste;